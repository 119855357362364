const initialState = ""
const changeStep  = (state=initialState,action) => {
    switch (action.type) {
               case "CHANGE":             
                return {
                    ...state,
                    formWizard: action.payload
                };   
                case "SETUSER":
                    return {
                        ...state,
                        tempuser:action.payload
                    }
                case "SETPAYMENT":
                    return {
                        ...state,
                        payment:action.payload
                    }
                case "SETLOGIN":
                    return {
                        ...state,
                        login:action.payload
                    }
                case "SETCODE":
                    return {
                        ...state,
                        affiliate:action.payload
                    }   
                default:
                return {...state};
           
    }
}
export default changeStep