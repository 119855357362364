import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Certificatebanner, TviWLogo } from "../../assets/images";
import "./tvi-redeem.css";
import axios from "../../axios/axios";
import LoadingOverlay from "react-loading-overlay";
import { message } from "antd";
import { useParams } from "react-router-dom";

const TviRedeem = () => {
  const [loading, setLoading] = useState(false);

  const { certificate_code, referral_code, sender_id } = useParams();

  const [input, setInput] = useState({
    name: "",
    email: "",
    reffer_code: "",
    certificate_code: "",
    sender_id: "",
  });

  useEffect(() => {
    setInput({
      ...input,
      reffer_code: referral_code,
      certificate_code,
      sender_id,
    });
  }, []);

  const handleOnChange = (e) => {
    let { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post("/reedem-travel-certificate", input)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          message.success(res.data.message);
          window.location.pathname = "thank-you";
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        message.error("Something Went Wrong...");
      });
  };

  // console.log("input", input);

  return (
    <section className="tvi_redeem">
      <LoadingOverlay
        className="d-flex flex-wrap"
        spinner
        active={loading}
        text="Loading..."
      >
        <div className="redeem_left">
          <div className="redeem_left_inner">
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-4 redeem__form"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  className="redeem__field"
                  required
                  name="name"
                  value={input.name}
                  onChange={handleOnChange}
                />
                <Form.Label>Your Name</Form.Label>
              </Form.Group>

              <Form.Group
                className="mb-4 redeem__form"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  className="redeem__field"
                  required
                  name="email"
                  value={input.email}
                  onChange={handleOnChange}
                />
                <Form.Label>Your Email</Form.Label>
              </Form.Group>
              <Form.Group
                className="mb-4 redeem__form"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter referral code"
                  className="redeem__field"
                  required
                  name="reffer_code"
                  value={input.reffer_code || referral_code}
                  onChange={handleOnChange}
                  readOnly={referral_code}
                />
                <Form.Label>Referral Code</Form.Label>
              </Form.Group>
              <Form.Group
                className="mb-4 redeem__form"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter certificate code"
                  className="redeem__field"
                  required
                  name="certificate_code"
                  value={input.certificate_code || certificate_code}
                  onChange={handleOnChange}
                  readOnly={certificate_code}
                />
                <Form.Label>Certificate Code</Form.Label>
              </Form.Group>
              <div className="redeem_submit">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="redeem_right">
          <div className="redeem_banner">
            <img alt="Certificatebanner" src={Certificatebanner} />
          </div>
          <div className="redeem_top_text new-cls">
            <img alt="LOGO" src={TviWLogo} />
            <div className="cert_trav">
              <h2>Ready to use your travel or restaurant certificate?</h2>
              <p>Choose from thousands of worldwide destinations or hundreds of thousands of restaurants. </p>
            </div>
            <h3>
              " Travel More, <span>Pay Less! "</span>
            </h3>
          </div>
        </div>
      </LoadingOverlay>
    </section>
  );
};
export default TviRedeem;
