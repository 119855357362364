import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/layout/layout";
import TviRedeem from "./pages/tvi-redeem/tvi-redeem";
import ThankYou from "./pages/tvi-redeem/thank-you";

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <RouteWrapper path="/" component={TviRedeem} layout={Layout} exact />
          <RouteWrapper
            path="/:referral_code/:certificate_code/:sender_id"
            component={TviRedeem}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/thank-you"
            component={ThankYou}
            layout={Layout}
            exact
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
