import Home_Img from "./home.png";
import Plane_Img from "./plane-icon.png";
import Shopping_Img from "./shopping-cart.png";
import User_Img from "./user-icon.png";
import Dollar_Img from "./dollar-icon.png";
import Calendar_Img from "./calendar-icon.png";
import Palm_Img from "./palm-icon.png";
import Relation_Img from "./relationship-icon.png";
import Logo from "./logo.png";
import Profile_Icon from "./user-profile-icon.png";
import Banner_1 from "./banner_1.png";
import Shopping from "./shopping.jpg";
import MyAccntBanner from "./signup-banner.jpg";
import ReferFriend from "./refer-a-friend.jpg";
import CErtificates from "./certificate.png";
import PlayIcon from "./play.png";
import Resources from "./resources.png";
import Partners from "./friend.png";
import Certificatebanner from "./certificate-banner.png";
import TrainingVideos from "./training-videos.png";
import ResourcesBanner from "./resources-banner.png";
import PrefferedPartners from "./preferred-partners.png";
import AffiliateDashboard from "./affiliate-dashboard.png";
import UserIcon from "./user.png";
import Email from "./email.png";
import Complimentary from "./complimentary.jpg";
import EmailInv from "./email_inv.png";
import NightMexico from "./5_night_mexico.png";
import Night7Mexico from "./7_night_mexico.png";
import Destination3500 from "./3500_destination.png";
import Dream7Night from "./7_night_dream.png";
import AllInclusive from "./all_inclusive.png";

import AddIcon from "./add_icon.png";
import DeleteIcon from "./delete.png";
import AddLink from "./add-link.png";
import CertDet from "./cert-det.png";
import UserDet from "./user-det.png";
import TransDet from "./trans-det.png";
import CertDet2 from "./cert-detils.png";
import TviWLogo from "./tvi_white_logo.png";
import Facebook from "./facebook.png";
import Google from "./google.png";
import EmailIcon from "./email_icon.png";
import MemberIcon from "./member.png";

import HeaderTwoLogo from "./ttripvalet-logo.png";
import LoginBanner from "./login_banner.jpg";
import Trip from "./trip.jpg";
import PDFFile from "./3500_destination.pdf";
import PaymntSuccess from "./paymnt-success.png";
import PaymntFailed from "./paymnt-failed.png";
import Logout from "./logout.png";
import DownArrow from "./down-arrow.png";
import ClickImg from "./click.png";
import CommissionsImg from "./discount.png";
import ImpressionsImg from "./impressions.png";
import RefundImg from "./refund.png";
import Dollar from "./dollar.png";
import Redeem from "./redeem.png";
export {
  Home_Img,
  Plane_Img,
  Shopping_Img,
  User_Img,
  Dollar_Img,
  Calendar_Img,
  Palm_Img,
  Relation_Img,
  Logo,
  Profile_Icon,
  Banner_1,
  Shopping,
  MyAccntBanner,
  ReferFriend,
  CErtificates,
  PlayIcon,
  Resources,
  Partners,
  Certificatebanner,
  TrainingVideos,
  ResourcesBanner,
  PrefferedPartners,
  AffiliateDashboard,
  UserIcon,
  Email,
  Complimentary,
  EmailInv,
  NightMexico,
  Night7Mexico,
  Destination3500,
  Dream7Night,
  AllInclusive,
  AddIcon,
  DeleteIcon,
  AddLink,
  CertDet,
  UserDet,
  TransDet,
  CertDet2,
  TviWLogo,
  Facebook,
  Google,
  EmailIcon,
  MemberIcon,
  HeaderTwoLogo,
  LoginBanner,
  Trip,
  PDFFile,
  PaymntSuccess,
  PaymntFailed,
  Logout,
  DownArrow,
  ClickImg,
  CommissionsImg,
  ImpressionsImg,
  RefundImg,
  Dollar,
  Redeem,
};
