import React from "react";

import {
    TviWLogo,
} from "../../assets/images";
import "./tvi-redeem.css";


const ThankYou = () => {
    return (
        <section className="thank_you_page">
            <div className="thank_you_inner_sec">
                <div className="tvi_logo">
                    <img src={TviWLogo} />
                </div>
                <p className="top_para cls"><b>Thank you!</b>  We have received your submission.
                    You’re on way to booking your next vacation or enjoying unlimited access to restaurants and entertainment venues.</p>

                <p className="bottom_para">Please check your email for further instructions.  If you don't see it right away, be sure and check your spam or junk (just in case).</p>
            </div>
        </section>
    );
};
export default ThankYou;